export const exsistingRoles = {
    KeyUser: 'keyuser',
    ServiceSupportUser: 'service-support-user',
    KeyUserManage: 'keyuser-manage',
    ServiceSupportAdmin: 'service-support-admin',
  }

export class RolePredictions {
    static AnyRole : RolePredictFunction = (x : Array<string>, y: Array<string>) => x.some(element => y.includes(element));
    static AllRoles : RolePredictFunction = (x : Array<string>, y: Array<string>) => x.every(element => y.includes(element));
}

export type RolePredictFunction = (requiredRoles: Array<string>, currentRoles: Array<string>) => boolean;